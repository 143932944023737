var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"align-items":"center","justify-content":"center"}},[_c('v-row',{staticClass:"select-estado"},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"md":"2"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.loading,"items":_vm.estadosAprobados,"auto-select-first":"","clearable":"","deletable-chips":"","small-chips":"","solo":"","label":"Seleccione el tipo de Crédito","outlined":"","hide-details":""},on:{"change":_vm.getAprobados},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)],1),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticStyle:{"float":"right"}},[_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{staticClass:"boton-exportar",attrs:{"color":"primary","loading":_vm.loadingExcel,"block":""},on:{"click":function($event){return _vm.exportarExcel()}}},[_vm._v("Exportar Excel")]),_c('br')],1)],1)])]),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"item-key":"id","loading":_vm.loading,"no-data-text":'No hay resultados',"footer-props":{
        'show-current-page': true,
      }},scopedSlots:_vm._u([{key:"item.desembolso",fn:function(ref){
      var item = ref.item;
return [(item.desembolso)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.desembolso)))]):_c('span',[_vm._v("S/Fecha")])]}},{key:"item.options",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
            name: 'Gestionar',
            params: {
              id: item.id,
              tipoCredito: item.tipo,
              cliente_id: item.cliente_id,
              estado: item.estado,
            },
          },"color":"primary"}},[_vm._v(" Gestionar ")])]}},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(ref){
          var header = ref.header;
return [(header.customFilter)?_c('v-text-field',{staticClass:"filter-input",attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","solo":"","single-line":""},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}}):_vm._e(),(!header.customFilter)?_c('v-text-field',{staticClass:"filter-input-disabled",attrs:{"hide-details":"","solo":"","single-line":"","disabled":""}}):_vm._e(),_c('span',{staticClass:"column-title"},[_vm._v(_vm._s(header.text))])]}}})],null,true)})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6"},[_c('h2',{staticClass:"mt-2 mb-1"},[_vm._v("Créditos Aprobados")])])}]

export { render, staticRenderFns }