<template>
  <v-card class='mx-auto' :loading='loading'>
    <v-card-subtitle>{{ titulo }}</v-card-subtitle>
    <v-card-text>
    <p class="display-1 text--primary">
        {{ cantidad }}
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'Indicador',
  props: ['modelo', 'titulo'],
  data: () => ({
    loading: false,
    cantidad: 0,
  }),
  methods: {
    async getCantidad() {
      this.loading = true;
      let url = '';
      if (this.modelo === 'unidadGestionClientes' || this.modelo === 'servicioClientes') {
        url = `${API_URL}api/admin/${this.modelo}`;
      }
      if (this.modelo === 'documentacionClientes') {
        url = `${API_URL}api/${this.modelo}`;
      }
      await axios
        .get(url, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          let index = 'documentacionClientes';
          if (this.modelo !== 'documentacionClientes') {
            index = this.modelo === 'unidadGestionClientes' ? 'fideicomisoCliente' : 'lineaCreditoCliente';
          }
          if (response.data.status === 'success') {
            this.cantidad = response.data[index].length;
          }
        })
        .catch((e) => {
          const { response } = e;
          if (response.status === 404) {
            this.$router.push('/');
          }
        });
      this.loading = false;
    },
  },
  created() {
    this.getCantidad();
  },
};
</script>
