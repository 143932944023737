var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mt-15"},[_vm._v("Analisis Legal")]),_c('hr'),_c('br'),_c('v-card',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Cliente")]),_c('th',{staticClass:"text-left"},[_vm._v("Operatoria")]),_c('th',{staticClass:"text-left"},[_vm._v("Estado")]),_c('th',{staticClass:"text-left"},[_vm._v("Opciones")])])]),_c('tbody',[(_vm.solicitudes.length < 1)?_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_vm._v("No hay documentación pendiente de analisis")])]):_vm._e(),_vm._l((_vm.solicitudes),function(s,index){return _c('tr',{key:index},[_c('td',[(s.cliente_nombre !== undefined && s.cliente_nombre !== '')?_c('span',[_vm._v(" "+_vm._s(s.cliente_nombre)+" "+_vm._s(s.cliente_apellido)+" ")]):_vm._e(),(s.cliente_rs !== undefined || s.cliente_rs !== '' )?_c('span',[_vm._v(" "+_vm._s(s.cliente_rs)+" ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(s.lineaCredito_nombre))]),_c('td',[(s.estado === 1 || s.estado === 3 || s.estado === 4)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(s.estado_nombre)+" ")]):_vm._e(),(s.estado === 5)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(s.estado_nombre)+" ")]):_vm._e(),(s.estado === 2 || s.estado === 6)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(s.estado_nombre)+" ")]):_vm._e()],1),_c('td',[_c('v-btn',{attrs:{"to":{
                  name: 'Gestionar',
                  params: {
                    id: s.lineacreditocliente_id, // lcc = 4
                    tipoCredito: 'lineaCredito',
                    cliente_id: s.cliente_id, // 3
                    estado: s.estado // 6
                  }},"color":"primary"}},[_vm._v(" Gestionar ")])],1)])})],2)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }