<template>
<v-card>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class='text-left'>
            <span>Operatorias</span>
          </th>
          <th class='text-left'>
            <span>Unidades de gestión</span>
          </th>
          <th class='text-left'>
            <span>Sectores productivos</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="solicitudes.length < 1">
          <td> No hay resultados</td>
          </tr>
        <tr v-for='item in solicitudes' :key='item.tableId'>
          <td>
            <span>
            {{ item.operatoria }}
            </span>
          </td>
          <td>
            <span>
            {{ item.unidad_de_gestion }}
            </span>
          </td>
          <td>
            <span>
            {{ item.sector_productivo }}
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-card>
</template>
<script>
import { API_URL } from '@/common/config';

import axios from 'axios';

export default {
  name: 'Operatorias',
  data: () => ({
    solicitudes: [],
    fideicomisosCliente: [],
    lineasCreditoCliente: [],
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
    noresult: false,
  }),
  methods: {
    setRolId() {
      this.rol_id = localStorage.getItem('rol');
    },
    async getData() {
      let url = '';
      url = `${API_URL}api/servicio/dashboard`;
      await axios.get(url, this.header).then((response) => {
        if (response.data.status === 'success') {
          const res = response.data.lineaCreditos.map((f, index) => ({
            operatoria: f.servicio_nombre,
            unidad_de_gestion: f.fideicomiso_nombre,
            sector_productivo: f.sectorProductivo_nombre,
            tableId: index,
          }));
          this.solicitudes = res;
          this.fideicomisosCliente = response.data.fideicomisoClientes;
        } else {
          this.noresult = true;
        }
      });
    },
  },
  created() {
    this.setRolId();
    this.getData();
  },
};
</script>
