<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="headers.length === 0 ? 350 : 1500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Movimientos Banco
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h6"> Adjuntar Excel </v-card-title>
      <v-card-text>
        <v-file-input
          label="Cargar archivo"
          accept=".xls, .xlsx, .xltx, .csv"
          v-model="file"
          outlined
          prepend-icon="mdi-paperclip"
          :rules="rules"
        ></v-file-input>
        <v-data-table
          v-if="informe.length !== 0"
          :headers="headers"
          :items="informe"
          item-key="id"
          class="elevation-1"
          no-data-text="No se han cargado datos aun"
          no-results-text="No hay resultados"
        >
          <template v-slot:item.MIGRACION="{ item }">
            <v-alert
              :color="item.MIGRACION === 'Exitosa' ? 'success' : 'error'"
              text
            >
              <strong>
                {{ item.MIGRACION }}
              </strong>
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="informe.length !== 0"
          color="green darken-1"
          text
          @click="reporteCsv"
        >
          Exportar Reporte
        </v-btn>
        <v-btn
          v-if="informe.length === 0"
          color="green darken-1"
          text
          :disabled="emptyFileInput"
          @click="test"
        >
          Cargar
        </v-btn>
        <v-btn color="red darken-1" text @click="salir()"> Salir </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { API_URL } from '@/common/config';
import { json2csvAsync } from 'json-2-csv';
import axios from 'axios';

export default {
  name: 'CargaBanco',
  props: ['operatoria'],
  data: () => ({
    emptyFileInput: true,
    dialog: false,
    file: {},
    headers: [],
    informe: [],
    header: {
      token: localStorage.getItem('token'),
    },
    rules: [
      (value) =>
        !value ||
        value.size <= 10000000 ||
        'El archivo debe ser menor o igual de 10 mb',
    ],
  }),
  watch: {
    file(val) {
      if (val !== null) {
        if (val.name !== '') {
          this.emptyFileInput = false;
        }
      } else {
        this.emptyFileInput = true;
      }
    },
  },
  methods: {
    async loadData() {
      const formData = new FormData();
      formData.append('migracion', this.file);
      const consulta = {
        method: 'post',
        url: `${API_URL}api/lineaCreditoClientes/migracion`,
        data: formData,
        headers: this.header,
      };
      const { data } = await axios(consulta).catch((e) => {
        console.error(e);
        this.$swal('Movimiento Banco', 'Ocurrió un error.', 'error');
      });
      this.informe = data.response;
      if (data.status === 'Error') {
        this.$swal(
          'Movimiento Banco',
          'Archivo con formato incorrecto',
          'error'
        );
        return;
      }
      this.$emit('informe-cargado');
      this.headers = Object.keys(this.informe[0]).map((el) => ({
        text: el,
        value: el,
      }));
    },
    async test() {
      if (this.operatoria === 19) {
        await this.$swal
          .fire({
            title: 'ANR',
            text: 'Recuerde "EXPORTAR EXCEL TRILAY", ¿Desea continuar, "Movimientos Banco"?',
            showCancelButton: true,
            confirmButtonColor: '#22bb33',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.loadData();
            } else if (result.isDenied) {
              return;
            }
          });
      } else if (this.operatoria !== 19) {
        this.loadData();
      }
    },
    async reporteCsv() {
      const csvContent = 'data:text/csv;charset=utf-8,';
      const csv = await json2csvAsync(this.informe);
      const data = encodeURI(csvContent.concat(csv));
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'Informe Cargado.csv');
      link.click();
    },
    salir() {
      this.informe = [];
      this.headers = [];
      this.dialog = false;
    },
  },
};
</script>
