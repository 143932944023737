var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex align-center justify-end",staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"disabled":_vm.selected.length < 1,"color":"primary"},on:{"click":_vm.enviarModuloCredito}},[(!_vm.cargando)?_c('span',[_vm._v(" Alta legajo credito ")]):_vm._e(),(_vm.cargando)?_c('v-progress-circular',{attrs:{"size":25,"color":"white","indeterminate":""}}):_vm._e()],1)],1),(_vm.operatoria_selected === 23)?_c('div',[_c('v-row',{staticClass:"d-flex align-end justify-space-between mb-2 mt-1"},[_c('v-col',{attrs:{"cols":"5"}},[_c('h2',[_vm._v("Programa Enlace")])]),_c('v-col',{staticClass:"d-flex align-center justify-end pb-2",attrs:{"cols":"3"}},[_c('span',{staticClass:"pr-3"},[_vm._v(" Legajos seleccionados: ")]),_c('v-card',{attrs:{"width":"90"}},[_c('h3',{staticClass:"d-flex align-center justify-center py-1"},[_vm._v(" "+_vm._s(_vm.selected.length)+" ")])])],1),_c('v-col',{staticClass:"d-flex align-center justify-end pb-2",attrs:{"cols":"4"}},[_c('span',{staticClass:"pr-3"},[_vm._v(" Subtotal seleccionados: ")]),_c('v-card',{attrs:{"width":"160"}},[_c('h3',{staticClass:"d-flex align-center justify-center py-1"},[_vm._v(" "+_vm._s(_vm.calculateSelectedSubtotal())+" ")])])],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"loading-text":"Cargando legajos...","headers":_vm.headersEnlace,"items":_vm.filteredItemsEnlace,"item-key":"id","show-select":"","loading":this.operatoria_selected ? _vm.cargando : false,"no-data-text":this.operatoria_selected && !this.cargando
            ? 'No hay resultados'
            : '',"footer-props":{
          'show-current-page': true,
        }},scopedSlots:_vm._u([{key:"item.nro_legajo",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v("# "+_vm._s(item.nro_legajo))])]}},{key:"item.fecha_liquidacion",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.fecha_liquidacion)))])]}},{key:"item.data_total",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.data_total)))])]}},_vm._l((_vm.headersEnlace),function(header){return {key:("header." + (header.value)),fn:function(ref){
        var header = ref.header;
return [(header.customFilter)?_c('v-text-field',{staticClass:"filter-input",attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","solo":"","single-line":""},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}}):_vm._e(),(!header.customFilter)?_c('v-text-field',{staticClass:"filter-input-disabled",attrs:{"hide-details":"","solo":"","single-line":"","disabled":""}}):_vm._e(),_c('span',{staticClass:"column-title"},[_vm._v(_vm._s(header.text))])]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e(),(_vm.operatoria_selected === 13)?_c('div',[_c('v-row',{staticClass:"d-flex align-end justify-space-between mb-2 mt-1"},[_c('v-col',{attrs:{"cols":"5"}},[_c('h2',[_vm._v("Financiamiento")])]),_c('v-col',{staticClass:"d-flex align-center justify-end pb-2",attrs:{"cols":"3"}},[_c('span',{staticClass:"pr-3"},[_vm._v(" Legajos seleccionados: ")]),_c('v-card',{attrs:{"width":"90"}},[_c('h3',{staticClass:"d-flex align-center justify-center py-1"},[_vm._v(" "+_vm._s(_vm.selected.length)+" ")])])],1),_c('v-col',{staticClass:"d-flex align-center justify-end pb-2",attrs:{"cols":"4"}},[_c('span',{staticClass:"pr-3"},[_vm._v(" Subtotal seleccionados: ")]),_c('v-card',{attrs:{"width":"160"}},[_c('h3',{staticClass:"d-flex align-center justify-center py-1"},[_vm._v(" "+_vm._s(_vm.calculateSelectedSubtotal())+" ")])])],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"loading-text":"Cargando legajos...","headers":_vm.headersFinanciamiento,"items":_vm.filteredItemsFinanciamiento,"item-key":"id","show-select":"","loading":this.operatoria_selected ? _vm.cargando : false,"no-data-text":!this.cargando && this.success ? 'No hay resultados' : '',"footer-props":{
          'show-current-page': true,
        },"item-class":_vm.checkFechaDesembolso},scopedSlots:_vm._u([{key:"item.nro_legajo",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v("# "+_vm._s(item.nro_legajo))])]}},{key:"item.fecha_liquidacion",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.fecha_liquidacion)))])]}},{key:"item.fecha_desembolso",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.fecha_desembolso ? 'SI' : 'NO'))])]}},{key:"item.data_total",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.data_total)))])]}},_vm._l((_vm.headersFinanciamiento),function(header){return {key:("header." + (header.value)),fn:function(ref){
        var header = ref.header;
return [(header.customFilter)?_c('v-text-field',{staticClass:"filter-input",attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","solo":"","single-line":""},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}}):_vm._e(),(!header.customFilter)?_c('v-text-field',{staticClass:"filter-input-disabled",attrs:{"hide-details":"","solo":"","single-line":"","disabled":""}}):_vm._e(),_c('span',{staticClass:"column-title"},[_vm._v(_vm._s(header.text))])]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e(),(_vm.operatoria_selected === 20)?_c('div',[_c('v-row',{staticClass:"d-flex align-end justify-space-between mb-2 mt-1"},[_c('v-col',{attrs:{"cols":"5"}},[_c('h2',[_vm._v("Mendoza Activa")])]),_c('v-col',{staticClass:"d-flex align-center justify-end pb-2",attrs:{"cols":"3"}},[_c('span',{staticClass:"pr-3"},[_vm._v(" Legajos seleccionados: ")]),_c('v-card',{attrs:{"width":"90"}},[_c('h3',{staticClass:"d-flex align-center justify-center py-1"},[_vm._v(" "+_vm._s(_vm.selected.length)+" ")])])],1),_c('v-col',{staticClass:"d-flex align-center justify-end pb-2",attrs:{"cols":"4"}},[_c('span',{staticClass:"pr-3"},[_vm._v(" Subtotal seleccionados: ")]),_c('v-card',{attrs:{"width":"160"}},[_c('h3',{staticClass:"d-flex align-center justify-center py-1"},[_vm._v(" "+_vm._s(_vm.calculateSelectedSubtotal())+" ")])])],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"loading-text":"Cargando legajos...","headers":_vm.headersMzaActiva,"items":_vm.filteredItemsMzaActiva,"item-key":"id","show-select":"","loading":this.cargando,"no-data-text":'',"footer-props":{
          'show-current-page': true,
        },"item-class":_vm.checkFechaDesembolso},scopedSlots:_vm._u([{key:"item.fecha_liquidacion",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.fecha_liquidacion)))])]}},{key:"item.fecha_desembolso",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.fecha_desembolso ? 'SI' : 'NO'))])]}},{key:"item.data_total",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.data_total)))])]}},_vm._l((_vm.headersMzaActiva),function(header){return {key:("header." + (header.value)),fn:function(ref){
        var header = ref.header;
return [(header.customFilter)?_c('v-text-field',{staticClass:"filter-input",attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","solo":"","single-line":""},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}}):_vm._e(),(!header.customFilter)?_c('v-text-field',{staticClass:"filter-input-disabled",attrs:{"hide-details":"","solo":"","single-line":"","disabled":""}}):_vm._e(),_c('span',{staticClass:"column-title"},[_vm._v(_vm._s(header.text))])]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }