<template>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          class="mr-5" color="info">
          <v-icon right dark>{{icon}}</v-icon>
          <span class="ml-2">{{ nombre }}</span>
        </v-btn>
      </template>
      <v-card>
        <v-alert v-if="success"
          border="bottom"
          color="success"
          dark
        >
        {{ successMensaje }}
        </v-alert>
        <v-alert v-if="error"
          border="bottom"
          color="error"
          dark
        >
        {{ successError }}
        </v-alert>
        <v-card-title>Cargar {{nombre}}</v-card-title>
        <v-card-subtitle>{{solicitudNombre}}</v-card-subtitle>
        <v-container>
        <v-row>
            <v-col cols="10">
        <v-file-input
            :label="nombre"
            v-model="file"
        ></v-file-input>
        </v-col>
        <v-col cols="2">
            <v-btn large
            :loading='loading'
            :disabled='file === ""' class="primary" @click="uploadFile">
              Subir
            </v-btn>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="10"></v-col>
          <v-col cols="2">
            <v-btn
              color="red"
              dark
            @click="dialog = false">
              Salir
            </v-btn>
          </v-col>
        </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  data: () => ({
    loading: false,
    dialog: false,
    file: '', // (String) Puede ser contrato o acta,
    successMensaje: '',
    success: false,
    successError: '',
    error: false,
  }),
  props: [
    'nombre',
    'icon',
    'tipo', // TRUE = ACTA | FALSE = CONTRATO
    'solicitudId',
    'solicitudNombre',
  ],
  methods: {
    async uploadFile() {
      this.loading = true;
      const url = `${API_URL}api/lineaCreditoClientes/actacontrato`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const data = new FormData();
      data.append('lineaCreditoCliente_id', this.solicitudId);
      data.append('tipo', this.tipo);
      data.append('adjunto', this.file);
      await axios.post(url, data, header).then((r) => {
        if (r.data.status === 'success') {
          this.success = true;
          this.successMensaje = `${this.nombre} subido con exito`;
        } else {
          this.error = true;
          this.successError = r.data.message;
        }
        this.loading = false;
      });
    },
  },
};
</script>
