var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mt-15"},[_vm._v("Solicitudes")]),_c('hr'),_c('br'),_c('v-card',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Cliente")]),_c('th',{staticClass:"text-left"},[_vm._v("Operatoria")]),_c('th',{staticClass:"text-left"},[_vm._v("Estado")]),(_vm.legal)?_c('th',{staticClass:"text-left"},[_vm._v("Fecha Evaluación")]):_vm._e(),_c('th',{staticClass:"text-left"},[_vm._v("Opciones")])])]),_c('tbody',[(_vm.solicitudes.length < 1)?_c('tr',[_c('td',[_vm._v(" No hay resultados")])]):_vm._e(),_vm._l((_vm.solicitudes),function(item,index){return _c('tr',{key:index},[((_vm.legal && item.contrato !== false)
          || (_vm.comite)
          || (_vm.legal && !item.dictamen))?[_c('td',[(item.cliente !== undefined && item.cliente !== '')?_c('span',[_vm._v(" "+_vm._s(item.cliente)+" ")]):_vm._e(),(item.rs !== undefined || item.rs !== '' )?_c('span',[_vm._v(" "+_vm._s(item.rs)+" ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(item.nombre))]),_c('td',[(item.estado === 1 || item.estado === 3 || item.estado === 4)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.findEstado(item.estado))+" ")]):_vm._e(),(item.estado === 5)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.findEstado(item.estado))+" ")]):_vm._e(),(item.estado === 2 || item.estado === 6)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.findEstado(item.estado))+" ")]):_vm._e()],1),(_vm.legal)?_c('td',[(_vm.legal && item.fecha_evaluacion_contrato !== 0)?_c('span',[_vm._v(" "+_vm._s(item.fecha_eval_contrato_format)+" ")]):_vm._e(),(_vm.comite && item.fecha_evaluacion_acta !== 0)?_c('span',[_vm._v(" "+_vm._s(item.fecha_eval_acta_format)+" ")]):_vm._e(),(item.contrato !== false && _vm.legal &&
              !item.contrato_aprobado && item.fecha_evaluacion_contrato === 0)?_c('span',[_vm._v("-")]):_vm._e(),(item.acta !== false && _vm.comite &&
              !item.acta_aprobada && item.fecha_evaluacion_acta === 0)?_c('span',[_vm._v("-")]):_vm._e()]):_vm._e(),_c('td',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("Opciones"),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[(_vm.comite)?_c('v-list-item',[_c('ModalActaContrato',{attrs:{"nombre":"Acta","icon":"mdi-note-plus-outline","solicitudId":item.id,"solicitudNombre":item.nombre,"tipo":true}})],1):_vm._e(),(item.acta !== false && _vm.comite)?_c('v-list-item',[_c('v-btn',{staticClass:"mr-3",attrs:{"target":"_blank","href":item.acta,"color":"primary"}},[_vm._v(" Descargar Acta ")])],1):_vm._e(),(item.acta !== false && _vm.comite &&
                      !item.acta_aprobada && item.fecha_evaluacion_acta === 0)?_c('v-list-item',[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.evaluar(item.id, 'acta')}}},[_vm._v(" Evaluar Acta ")])],1):_vm._e(),(item.acta !== false && _vm.comite &&
                        item.acta_aprobada === false && item.fecha_evaluacion_acta !== 0)?_c('v-list-item',[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"info"},on:{"click":function($event){return _vm.evaluar(item.id, 'acta')}}},[_vm._v(" Re-Evaluar Acta ")])],1):_vm._e(),(item.contrato !== false && _vm.legal)?_c('v-list-item',[_c('v-btn',{staticClass:"mr-3",attrs:{"target":"_blank","href":item.contrato,"color":"primary"}},[_vm._v(" Descargar Contrato ")])],1):_vm._e(),(item.contrato !== false && _vm.legal &&
                        !item.contrato_aprobado && item.fecha_evaluacion_contrato === 0)?_c('v-list-item',[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.evaluar(item.id, 'contrato')}}},[_vm._v(" Evaluar Contrato ")])],1):_vm._e(),(item.contrato !== false && _vm.legal &&
                        item.contrato_aprobado === false && item.fecha_evaluacion_contrato !== 0)?_c('v-list-item',[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"info"},on:{"click":function($event){return _vm.evaluar(item.id, 'contrato')}}},[_vm._v(" Re-Evaluar Contrato ")])],1):_vm._e(),(!item.dictamen)?_c('v-list-item',[_c('ModalDictamen',{attrs:{"nombre":"Subir Dictamen","solicitudId":item.id,"solicitudNombre":item.nombre}})],1):_vm._e(),(item.dictamen !== false && _vm.legal)?_c('v-list-item',[_c('v-btn',{staticClass:"mr-3",attrs:{"target":"_blank","href":item.dictamen,"color":"primary"}},[_vm._v(" Descargar Dictamen ")])],1):_vm._e(),(item.contrato_aprobado && item.dictamen && _vm.legal
                          && item.documentacion_aprobada && item.estado !== 6)?_c('v-list-item',[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"success"},on:{"click":function($event){return _vm.aprobarLegajo(item.id ,6 ,item.cliente_id)}}},[_vm._v(" Aprobar Legajo ")])],1):_vm._e()],1)],1),_c('br'),(_vm.legal && item.contrato_aprobado && item.fecha_evaluacion_contrato !== 0)?_c('span',[_c('b',{staticClass:"green--text"},[_vm._v("Contrato aprobado")])]):_vm._e(),(_vm.comite && item.acta_aprobada && item.fecha_evaluacion_acta !== 0)?_c('span',[_c('b',{staticClass:"green--text"},[_vm._v("Acta aprobado")])]):_vm._e(),(_vm.comite && !item.acta_aprobada && item.fecha_evaluacion_acta !== 0)?_c('span',[_c('b',{staticClass:"red--text"},[_vm._v("Acta Rechazada")])]):_vm._e(),(_vm.legal && !item.contrato_aprobado && item.fecha_evaluacion_contrato !== 0)?_c('span',[_c('b',{staticClass:"red--text"},[_vm._v("Contrato Rechazado")])]):_vm._e()],1)]:[(_vm.solicitudes.length < 1)?_c('td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v(" No hay Solicitudes pendientes ")]):_vm._e()]],2)})],2)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }