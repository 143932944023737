<template>
  <v-dialog v-model="visibleModalInforme" width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Informe Mendoza Activa II</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <template>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaInicio"
                      label="Desde fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaInicio"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaFinal"
                      label="Hasta fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaFinal"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                <v-autocomplete
                  v-model="operatoria"
                  :items="listaOperatorias"
                  label="Seleccionar tipo de operatoria"
                  hide-no-data
                  hide-selected
                ></v-autocomplete>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="informe(0)" class="mr-3 ml-5 mb-2" color="info">
                <span v-if="!isLoading"> Ver informe </span>
                <v-progress-circular
                  v-if="isLoading"
                  :size="25"
                  color="white"
                  indeterminate
                ></v-progress-circular>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn @click="informe(1)" class="mr-3 mb-2" color="info">
                <span v-if="!isLoading"> Guardar Informe </span>
                <v-progress-circular
                  v-if="isLoading"
                  :size="25"
                  color="white"
                  indeterminate
                ></v-progress-circular>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn @click="informe(2)" class="mr-3 mb-2" color="success">
                <span v-if="!isLoading"> Guardar excel </span>
                <v-progress-circular
                  v-if="isLoading"
                  :size="25"
                  color="white"
                  indeterminate
                ></v-progress-circular>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                @click="salir"
                color="red darken-1"
                class="white--text mb-2"
              >
                Salir
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import logo from '@/common/logo';
import { API_URL } from '@/common/config';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import xlsx from 'xlsx';

export default {
  props: ['visibleModalInforme'],
  data: () => ({
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
    operatoria: '',
    isLoading: false,
    dialog: false,
    informes: [],
    informesPDF: [],
    menu1: false,
    menu2: false,
    fechaInicio: '',
    fechaFinal: '',
    listaOperatorias: [],
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    async informe(tipo) {
      this.isLoading = true;
      const url = `${API_URL}api/liquidacion/informe`;
      const data = {
        tipo_operatoria_id: '20',
        fecha_inicio: this.fechaInicio,
        fecha_final: this.fechaFinal,
        operatoria: this.operatoria,
      };
      await axios.post(url, data, this.header).then((response) => {
        if (response.data.status === 'success') {
          const informes = response.data.informe_liquidaciones;
          this.informes = informes.map(
            ({ legajo, fecha_creacion, fecha_liquidacion, ...resto }) => ({
              legajo,
              fecha_creacion,
              fecha_liquidacion,
              ...resto,
              invAprobada: parseFloat(resto.invAprobada),
              credito_fiscal: parseFloat(resto.credito_fiscal),
              billetera: parseFloat(resto.billetera),
              efectivo: parseFloat(resto.efectivo),
              total_neto: parseFloat(resto.total_neto),
              montoAnr: parseFloat(resto.montoAnr),
            })
          );
          this.informesPDF = informes.map(
            ({ legajo, fecha_creacion, fecha_liquidacion, ...resto }) => ({
              legajo,
              fecha_creacion,
              fecha_liquidacion,
              ...resto,
              invAprobada: this.formatter.format(resto.invAprobada),
              credito_fiscal: this.formatter.format(resto.credito_fiscal),
              billetera: this.formatter.format(resto.billetera),
              efectivo: this.formatter.format(resto.efectivo),
              total_neto: this.formatter.format(resto.total_neto),
              montoAnr: this.formatter.format(resto.montoAnr),
            })
          );
          this.exportPdf(tipo);
          this.fechaInicio = '';
          this.fechaFinal = '';
          this.operatoria = null;
          this.isLoading = false;
        }
      });
      this.isLoading = false;
    },
    async getListaOperatorias() {
      const url = `${API_URL}api/servicio`;
      const { data } = await axios.get(url, this.header);
      this.listaOperatorias = data.lineaCreditos.reduce((acc, el) => {
        if (el.tipo_servicio_id.id === 20) {
          return acc.concat({ text: el.nombre, value: el.id });
        }
        return acc;
      }, []);
      this.listaOperatorias.unshift({ text: 'General', value: '' });
    },
    exportPdf(tipo) {
      if (!this.informesPDF || this.informesPDF.length === 0) {
        return 0;
      }
      const fixedColumnWidths = [
        50, 30, 30, 30, 20, 20, 15, 25, 25, 30, 20, 20, 15, 15, 15, 15,
      ];
      const doc = new JsPDF('l', 'mm', [297, 410]);
      const columnsHands = [
        { title: 'Legajo', dataKey: 'legajo' },
        { title: 'Fecha Solicitud', dataKey: 'fecha_creacion' },
        { title: 'Fecha Liquidación', dataKey: 'fecha_liquidacion' },
        { title: 'Fecha Desembolso', dataKey: 'fecha_desembolso' },
        { title: 'Nº Ticket', dataKey: 'numero_ticket' },
        { title: 'Nº Liquidación', dataKey: 'orden' },
        { title: 'Nº Empleados', dataKey: 'nro_empleados' },
        { title: 'CUIL/CUIT', dataKey: 'cuit_beneficiario' },
        { title: 'CBU', dataKey: 'cbu' },
        { title: 'Beneficiario', dataKey: 'nombre_beneficiario' },
        { title: 'Email', dataKey: 'mail' },
        { title: 'Teléfono', dataKey: 'telefono' },
        { title: 'Efectivo', dataKey: 'efectivo' },
        { title: 'Billetera', dataKey: 'billetera' },
        { title: 'Credito Fiscal', dataKey: 'credito_fiscal' },
        { title: 'Total Neto', dataKey: 'total_neto' },
        { title: 'Operatoria', dataKey: 'operatoria' },
      ];
      const maxColumnWidths = columnsHands.map((column) => {
        // Obtener el ancho máximo del contenido de cada columna

        const maxWidth = this.informesPDF.reduce((max, row) => {
          const cellValue = String(row[column.dataKey]);
          const width = doc.getStringUnitWidth(cellValue);
          return Math.max(max, width);
        }, 0);
        return maxWidth + 13;
      });

      const height = 50;
      const imgData = logo;
      doc.addImage(imgData, 'PNG', 10, 5, 48, 20);
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('Informe Mendoza Activa II', 15, 30);
      doc.setFontSize(13);
      doc.text(`Fecha: ${moment().format('DD/MM/YYYY')}`, 150, 30);
      doc.setFontSize(15);
      doc.text(`Periodo: ${moment().format('DD/MM/YYYY')}`, 15, 40);
      doc.autoTable(columnsHands, this.informesPDF, {
        startY: height,
        columnWidth: tipo === 1 ? 'auto' : fixedColumnWidths,
        columnStyles: columnsHands.map((column, index) => ({
          cellWidth:
            maxColumnWidths[index] -
            (column.dataKey === 'nro_empleados' ? 1 : 0) -
            (column.dataKey === 'orden' ? 1 : 0),
        })),
        styles: {
          fontSize: 6,
          cellPadding: 1,
          lineHeight: 0.2, // Aumenta el espacio entre líneas
          halign: 'center',
          valign: 'middle',
        },
        theme: 'grid',
        margin: { left: 5, right: 5, top: 5, bottom: 5 },
        headStyles: {
          fillColor: [0, 0, 255], // Color de fondo del encabezado
          textColor: [255, 255, 255], // Color del texto del encabezado
        },
      });
      if (tipo === 0) {
        window.open(doc.output('bloburl'), '_blank');
      }
      if (tipo === 1) {
        doc.save(
          `Informe Mendoza Activa II ${moment().format('DD/MM/YYYY')}.pdf`
        );
        this.fechaInicio = '';
        this.fechaFinal = '';
        this.operatoria = null;
        this.$emit('update:visibleModalInforme', false);
      }
      if (tipo === 2) {
        const informes =
          this.informes.length !== 0
            ? this.informes.map((el) => ({
                'Nº Legajo': el.legajo,
                'Fecha Solicitud': el.fecha_creacion,
                'Fecha Liquidación': el.fecha_liquidacion,
                'Fecha Desembolso': '',
                'Nº Ticket': el.numero_ticket,
                'Nº Liquidación': el.orden,
                'Nº Empleados': el.nro_empleados,
                'CUIL/CUIT': el.cuit_beneficiario,
                CBU: el.cbu,
                Beneficiario: el.nombre_beneficiario,
                Email: el.mail,
                Telefono: el.telefono,
                Efectivo: el.efectivo,
                Billetera: el.billetera,
                'Credito Fiscal': el.credito_fiscal,
                'Total Neto': el.total_neto || 0,
                Operatoria: el.operatoria,
                'Inv. Aprobada': el.invAprobada || 0,
                'Monto ANR': el.montoAnr || 0,
                Estado: el.estado,
              }))
            : [
                {
                  'Nº Legajo': '',
                  'Fecha Solicitud': '',
                  'Fecha Liquidación': '',
                  'Fecha Desembolso': '',
                  'Nº Ticket': '',
                  'Nº Liquidación': '',
                  'Nº Empleados': '',
                  'CUIL/CUIT': '',
                  Beneficiario: '',
                  Email: '',
                  Telefono: '',
                  Efectivo: '',
                  Billetera: '',
                  'Credito Fiscal': '',
                  'Total IVA': '',
                  Total: '',
                  Operatoria: '',
                  'Inv. Aprobada': '',
                  'Monto ANR': '',
                  Estado: '',
                },
              ];
        const ws = xlsx.utils.json_to_sheet(informes);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'People');
        xlsx.writeFile(
          wb,
          `Informe Mendoza Activa II ${moment().format('DD/MM/YYYY')}.xlsx`
        );
      }
    },
    salir() {
      this.fechaInicio = '';
      this.fechaFinal = '';
      this.operatoria = null;
      this.$emit('update:visibleModalInforme', false);
    },
  },
  created() {
    this.getListaOperatorias();
  },
};
</script>
<style>
.v-date-picker-table--date td {
  padding: 0px !important;
}
</style>
