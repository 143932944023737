<template>
  <v-card class="mt-5 mx-auto" :loading="loading">
    <v-card-subtitle>{{ clave }}</v-card-subtitle>
    <v-card-text>
      <p class="display-1 text--primary">
        {{ valor === true ? 'Encendido' : 'Apagado' }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="success"
        :loading="loadingSwitch"
        :disabled="loadingSwitch"
        v-if="valor === false"
        @click="setParam(clave, 'true')"
      >
        Encender
      </v-btn>
      <v-btn
        color="error"
        :loading="loadingSwitch"
        :disabled="loadingSwitch"
        v-if="valor === true"
        @click="setParam(clave, 'false')"
      >
        Apagar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'ParamView',
  props: ['clave'],
  data: () => ({
    valor: false,
    loading: false,
    loadingSwitch: false,
  }),
  methods: {
    async getParam(clave) {
      const url = `${API_URL}api/parametros/${clave}`;
      axios
        .get(url, { headers: { token: localStorage.getItem('token') } })
        .then((result) => {
          if (result.data.param[0].valor === 'true') {
            this.valor = true;
          }
        });
    },
    async setParam(clave, valor) {
      this.loadingSwitch = true;
      const url = `${API_URL}api/parametros/${clave}/${valor}`;
      axios
        .get(url, { headers: { token: localStorage.getItem('token') } })
        .then((result) => {
          this.loadingSwitch = false;
          if (result.data.param.valor === 'true') {
            this.valor = true;
          } else {
            this.valor = false;
          }
        });
    },
  },
  created() {
    this.getParam(this.clave);
  },
};
</script>
