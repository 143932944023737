<template>
    <v-dialog v-model="dialog" max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          class="mr-5" color="primary">
          Auditar
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Cargar Auditoria
        </v-card-title>
        <v-file-input
          v-model='file'
          class="ml-2 mr-2"
          label='Informe'>
        </v-file-input>
        <v-textarea
          outlined
          class="ml-2 mr-2"
          name="input-7-4"
          label="Informe Bítacora"
          value="El día de la fecha se deja constancia de la Auditoria realizada."
          v-model='mensaje'
        ></v-textarea>
        <v-container>
            <v-btn
            :loading='loading'
            :disabled='file === null' class="primary mr-2 success" @click="subirAuditoria()">
              Regular
            </v-btn>
            <v-btn
            :loading='loading'
            :disabled='file === null' class="primary mr-2 warning"
            @click="subirAuditoria('Incumplido')">
              Incumplido
            </v-btn>
            <v-btn
              color="red"
              dark
            @click="dialog = false">
              Salir
            </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  data: () => ({
    loading: false,
    dialog: false,
    file: null,
    mensaje: 'El día de la fecha se deja constancia de la Auditoria realizada.',
  }),
  props: ['lccId'],
  methods: {
    async subirAuditoria(estado) {
      const formData = new FormData();
      formData.append('mensaje', this.mensaje);
      formData.append('lineacreditocliente_id', this.lccId);
      formData.append('estado', estado);
      formData.append('informeFile', this.file);
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem('token'),
        },
      };
      const url = `${API_URL}api/bitacoraDocumentacion/auditoria`;
      const { data: { status, message } } = await axios
        .post(url, formData, header);
      if (status === 'success') {
        await this.$swal.fire('Auditoria Exitosa!!', '', 'success');
        this.$router.go(0);
      }
      if (status === 'error') {
        console.error(message);
        await this.$swal.fire('Algo salio mal!!', '', 'error');
      }
      this.dialog = false;
    },
  },
};
</script>
