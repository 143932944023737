<template>
    <v-dialog v-model="visibleModalInformeEnlace" width="800px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">Informe Programa ENLACE</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <template>
                                <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="fechaInicio"
                                            label="Desde fecha"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="fechaInicio"
                                    @input="menu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="fechaFinal"
                                        label="Hasta fecha"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="fechaFinal"
                                    @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>
                            </template>
                               <v-text-field
                        label="Numero de Acta"
                        prepend-icon="mdi-pound"
                        v-model="filtroacta">
                        </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="informe(0)"
                                class="mr-3 ml-5 mb-2"
                                color="info"
                            >
                                Ver informe
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn @click="informe(1)"
                                class="mr-3 mb-2"
                                color="info"
                            >
                                Guardar informe
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn @click="informe(2)"
                                class="mr-3 mb-2"
                                color="success"
                            >
                                Guardar Excel
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                @click="salir"
                                color="red darken-1"
                                class="white--text mb-2"
                            >
                                Salir
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import logo from '@/common/logo';
import { API_URL } from '@/common/config';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import xlsx from 'xlsx';

export default {
  props: ['visibleModalInformeEnlace'],
  data: () => ({
    dialog: false,
    informes: [],
    menu1: false,
    filtroacta: '',
    menu2: false,
    fechaInicio: '',
    fechaFinal: '',
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    async informe(tipo) {
      const url = `${API_URL}api/liquidacion/informeProgramaEnlace`;
      const data = {
        // tipo_operatoria_id: '20',
        fecha_inicio: this.fechaInicio,
        fecha_final: this.fechaFinal,
      };
      if (this.filtroacta !== 0) {
        data.acta = this.filtroacta;
      }
      await axios.post(url, data, this.header).then((response) => {
        if (response.data.status === 'success') {
          this.informes = response.data.lineaCreditoClienteFiltrada;
          this.exportPdf(tipo);
          this.fechaInicio = '';
          this.fechaFinal = '';
        }
      });
    },
    exportPdf(tipo) {
      const doc = new JsPDF('l', 'mm', [297, 410]);
      const columnsHands = [
        { title: 'Fecha solicitud', dataKey: 'fecha_creacion' },
        { title: 'Fecha liquidación', dataKey: 'fecha_liquidacion' },
        { title: 'Fecha desembolso', dataKey: 'fecha_desembolso' },
        { title: 'Nº legajo', dataKey: 'nro_legajo' },
        { title: 'Nº acta', dataKey: 'nro_acta' },
        { title: 'Nº liquidación', dataKey: 'numero_liquidacion' },
        { title: 'CUIL/CUIT', dataKey: 'cuit_beneficiario' },
        { title: 'CBU', dataKey: 'cbu' },
        { title: 'Beneficiario', dataKey: 'nombre_beneficiario' },
        { title: 'Efectivo', dataKey: 'efectivo' },
        { title: 'Crédito fiscal', dataKey: 'credito_fiscal' },
        { title: 'Operatoria', dataKey: 'operatoria' },
        { title: 'Total', dataKey: 'total' },
      ];
      const height = 50;
      const imgData = logo;
      doc.addImage(imgData, 'PNG', 10, 5, 48, 20);
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('Informe Programa ENLACE', 15, 30);
      doc.setFontSize(13);
      doc.text(`Fecha: ${moment().format('DD/MM/YYYY')}`, 150, 30);
      doc.setFontSize(15);
      doc.text(`Periodo: ${moment().format('DD/MM/YYYY')}`, 15, 40);
      doc.autoTable(columnsHands, this.informes, {
        startY: height,
        columnStyles: { text: { cellWidth: 'auto' } },
      });
      if (tipo === 0) {
        window.open(doc.output('bloburl'), '_blank');
      }
      if (tipo === 1) {
        doc.save(`Informe Programa ENLACE ${moment().format('DD/MM/YYYY')}.pdf`);
        this.fechaInicio = '';
        this.fechaFinal = '';
        this.$emit('update:visibleModalInformeEnlace', false);
      }
      if (tipo === 2) {
        const informes = this.informes.length !== 0
          ? this.informes.map((el) => ({
            'Fecha solicitud': el.fecha_creacion,
            'Fecha liquidación': el.fecha_liquidacion,
            'Fecha desembolso': el.fecha_desembolso,
            'Nº legajo': el.nro_legajo,
            'Nº acta': el.nro_acta,
            'Nº liquidación': el.numero_liquidacion,
            'CUIL/CUIT': el.cuit_beneficiario,
            CBU: el.cbu,
            Beneficiario: el.nombre_beneficiario,
            Efectivo: el.efectivo,
            'Crédito fiscal': el.credito_fiscal,
            Operatoria: el.operatoria,
            Total: el.total,
          }))
          : [{
            'Fecha solicitud': '',
            'Fecha liquidación': '',
            'Fecha desembolso': '',
            'Nº legajo': '',
            'Nº acta': '',
            'Nº liquidación': '',
            'CUIL/CUIT': '',
            CBU: '',
            Beneficiario: '',
            Efectivo: '',
            'Crédito fiscal': '',
            Operatoria: '',
            Total: '',
          }];
        const ws = xlsx.utils.json_to_sheet(informes);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'People');
        xlsx.writeFile(wb, 'sheetjs.xlsx');
      }
    },
    salir() {
      this.fechaInicio = '';
      this.fechaFinal = '';
      this.$emit('update:visibleModalInformeEnlace', false);
    },
  },
};
</script>
<style>
.v-date-picker-table--date td {
  padding: 0px !important;
}
</style>
