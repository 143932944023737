<template>
    <v-dialog v-model="dialog" max-width="800px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="mr-5" color="info">
          {{nombre}}
        </v-btn>
      </template>
      <v-card>
          <v-alert v-if="success"
      border="bottom"
      color="success"
      dark
    >
      {{ successMensaje }}
    </v-alert>
       <v-alert v-if="error"
      border="bottom"
      color="error"
      dark
    >
      {{ successError }}
    </v-alert>
      <v-container>
        <v-row>
          <v-col>
            <v-card-title>Cargar Dictamen</v-card-title>
          </v-col>
          <v-col
            class="d-flex justify-end"
          >
            <v-btn
              color="red dark-red"
              dark
              icon
              @click="salir"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
        <v-container>
        <v-row>
            <v-col cols="10">
        <v-file-input
            :label="nombre"
            v-model="file"
        ></v-file-input>
        </v-col>
        <v-col cols="2">
            <v-btn large
            :loading='loading'
            :disabled='file === ""' class="primary" @click="uploadFile">Subir</v-btn>
        </v-col>
        </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  data: () => ({
    loading: false,
    dialog: false,
    file: {},
    successMensaje: '',
    success: false,
    successError: '',
    error: false,
  }),
  props: [
    'nombre',
    'solicitudId',
    'solicitudNombre',
  ],
  methods: {
    salir() {
      this.$router.go('/');
      this.dialog = false;
    },
    async uploadFile() {
      this.loading = true;
      const url = `${API_URL}api/lineaCreditoClientes/dictamen`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const data = new FormData();
      data.append('lineaCreditoCliente_id', this.solicitudId);
      data.append('adjunto', this.file);
      await axios.post(url, data, header).then((r) => {
        if (r.data.status === 'success') {
          this.success = true;
          this.successMensaje = `${this.nombre} subido con exito`;
        } else {
          this.error = true;
          this.successError = r.data.message;
        }
        this.loading = false;
      });
    },
  },
};
</script>
