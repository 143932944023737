<template>
  <div>
    <h2 class="mt-5">Créditos Regulares</h2>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        item-key="id"
        :loading="loading"
        :no-data-text="'No hay resultados'"
        :footer-props="{
          'show-current-page': true,
        }"
      >
        <template v-slot:item.otorgado="{ item }">
          <span>{{ formatDate(item.otorgado) }}</span>
        </template>
        <template v-slot:item.estado="{ item }">
          <span>{{ item.estado.nombre }}</span>
        </template>
        <template v-slot:item.gestionar="{ item }">
          <v-btn
            :to="{
              name: 'Gestionar',
              params: {
                id: item.legajo,
                tipoCredito: item.tipo,
                cliente_id: item.cliente_id,
                estado: item.estado_id,
              },
            }"
            color="primary"
          >
            Gestionar
          </v-btn>
        </template>
        <template
          v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }"
        >
          <v-text-field
            v-if="header.customFilter"
            class="filter-input"
            v-model="filters[header.value]"
            append-icon="mdi-magnify"
            clearable
            hide-details
            solo
            single-line
          ></v-text-field>
          <v-text-field
            v-if="!header.customFilter"
            class="filter-input-disabled"
            hide-details
            solo
            single-line
            disabled
          ></v-text-field>
          <span class="column-title">{{ header.text }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { API_URL } from '@/common/config';
import axios from 'axios';
import moment from 'moment';
import { VDataTable } from 'vuetify/lib';

export default {
  name: 'Regulares',
  components: {
    VDataTable,
  },
  data: () => ({
    loading: false,
    otorgados: [],
    solicitudes: [],
    listaEstado: [],
    headers: [
      {
        text: 'N° Legajo',
        value: 'id',
        align: 'center',
        width: 120,
        sortable: true,
        customFilter: (value, item) => item.id.includes(value),
      },
      {
        text: 'Fecha Desembolso',
        value: 'otorgado',
        align: 'center',
        width: 170,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.otorgado).format('DD/MM/YYYY').includes(value),
      },
      {
        text: 'Operatoria',
        value: 'operatoria',
        align: 'center',
        width: 260,
        sortable: true,
        customFilter: (value, item) =>
          item.operatoria.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'DNI/CUIT',
        value: 'dniCuit',
        align: 'center',
        width: 160,
        sortable: false,
        customFilter: (value, item) => item.dniCuit.includes(value),
      },
      {
        text: 'Cliente',
        value: 'clienteTable',
        align: 'center',
        sortable: true,
        customFilter: (value, item) =>
          item.clienteTable.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Gestionar',
        value: 'gestionar',
        align: 'center',
        width: 160,
        sortable: false,
      },
    ],
    filters: {},
  }),
  computed: {
    filteredItems() {
      const filteredItems = this.otorgados.filter((item) => {
        if (this.filters['id']) {
          const filterValue = parseInt(this.filters['id'], 10);
          return item['id'] === filterValue;
        }
        return Object.keys(this.filters).every((key) => {
          if (key === 'nro_legajo') return true;
          const filterValue = this.filters[key];
          if (!filterValue) return true;

          if (key === 'otorgado') {
            return moment(item.otorgado)
              .format('DD/MM/YYYY')
              .includes(filterValue);
          }

          return item[key].toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      return filteredItems;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return 'SIN FECHA';
      return moment(date).format('DD/MM/YYYY');
    },
    getOtorgados() {
      this.loading = true;
      axios
        .get(`${API_URL}api/admin/servicioClientes/credito`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            const data = response.data.lineaCreditoCliente;
            this.otorgados = data
              .map((el, index) => ({
                id: el.lcc.id,
                dni: el.cliente.dni,
                cuit: el.cliente.cuit,
                //ADD new property
                dniCuit: el.cliente.dni ? el.cliente.dni : el.cliente.cuit,
                cliente_id: el.cliente.id,
                cliente: `${el.cliente.apellido} ${el.cliente.nombre}`,
                razonSocial: el.cliente.razonSocial,
                //ADD new property
                clienteTable:
                  el.cliente.apellido !== '' && el.cliente.nombre !== ''
                    ? `${el.cliente.apellido} ${el.cliente.nombre}`
                    : el.cliente.razonSocial,
                estado_id: el.lcc.estado_id,
                tipo: 'lineaCredito',
                // otorgado: el.lcc.createdAt,
                otorgado: el.lcc.fecha_desembolso,
                legajo: el.lcc.id,
                operatoria: el.lineaCredito.nombre,
                saldoDeuda: '------',
                estado: this.listaEstado.find(
                  (elem) => elem.nombre === 'Otorgado'
                ),
              }))
              .filter((el) => el.estado_id === el.estado.id);
          }
          this.loading = false;
        });
    },
    getListaSolicitudEstados() {
      axios
        .get(`${API_URL}api/solicitudEstado`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.listaEstado = response.data.solicitudEstado;
        });
    },
  },
  created() {
    moment.locale('es');
    this.getListaSolicitudEstados();
    this.getOtorgados();
  },
};
</script>
<style>
th,
td {
  padding: 0 3px !important;
}
input {
  font-size: 14px;
  font-weight: 500;
  color: #1976d2 !important;
}
.v-input__control {
  margin: 5px;
}
.v-input__slot {
  height: 30px;
  min-height: 30px !important;
  margin: 6px 0 0 0;
  padding: 0 6px !important;
}
.filter-input-disabled .v-input__control .v-input__slot {
  background-color: rgb(239, 239, 239) !important;
  visibility: hidden !important;
}
.column-title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 1rem;
}
</style>
