<template>
<div>
  <h1 class="mt-15">Solicitudes</h1>
  <hr><br>
  <v-card>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class='text-left'>Cliente</th>
          <th class='text-left'>Operatoria</th>
          <th class='text-left'>Estado</th>
          <th v-if="legal" class='text-left'>Fecha Evaluación</th>
          <th class='text-left'>Opciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="solicitudes.length < 1">
          <td> No hay resultados</td>
          </tr>
        <tr v-for='(item, index) in solicitudes' :key='index'>
          <template v-if="(legal && item.contrato !== false)
          || (comite)
          || (legal && !item.dictamen)">
            <td>
              <span v-if="item.cliente !== undefined && item.cliente !== ''">
              {{ item.cliente }}
              </span>
              <span v-if="item.rs !== undefined || item.rs !== '' ">
              {{ item.rs }}
              </span>
            </td>
            <td>{{ item.nombre }}</td>
            <td>
              <v-chip class='ma-2'
              v-if='item.estado === 1 || item.estado === 3 || item.estado === 4'
              color="warning">
                {{ findEstado(item.estado) }}
              </v-chip>
              <v-chip class='ma-2'
              v-if='item.estado === 5'
              color="error">
                {{ findEstado(item.estado) }}
              </v-chip>
              <v-chip class='ma-2'
              v-if='item.estado === 2 || item.estado === 6'
              color="success">
                {{ findEstado(item.estado) }}
              </v-chip>
            </td>
            <td v-if="legal">
              <span v-if="legal && item.fecha_evaluacion_contrato !== 0">
              {{ item.fecha_eval_contrato_format }}
              </span>
              <span v-if="comite && item.fecha_evaluacion_acta !== 0">
              {{ item.fecha_eval_acta_format }}
              </span>
              <span v-if="item.contrato !== false && legal &&
              !item.contrato_aprobado && item.fecha_evaluacion_contrato === 0">-</span>
              <span v-if="item.acta !== false && comite &&
              !item.acta_aprobada && item.fecha_evaluacion_acta === 0">-</span>
            </td>
            <td>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark
                    v-bind="attrs" v-on="on">Opciones<v-icon dark right>
                    mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                  <v-list>
                    <v-list-item v-if="comite">
                      <ModalActaContrato
                        nombre="Acta"
                        icon="mdi-note-plus-outline"
                        :solicitudId="item.id"
                        :solicitudNombre="item.nombre"
                        :tipo="true">
                      </ModalActaContrato>
                    </v-list-item>
                    <v-list-item  v-if="item.acta !== false && comite">
                      <v-btn class="mr-3" target="_blank" :href="item.acta" color="primary">
                        Descargar Acta
                      </v-btn>
                    </v-list-item>
                    <v-list-item   v-if="item.acta !== false && comite &&
                      !item.acta_aprobada && item.fecha_evaluacion_acta === 0">
                      <v-btn class="mr-3" @click="evaluar(item.id, 'acta')" color="primary">
                        Evaluar Acta
                      </v-btn>
                    </v-list-item>
                    <v-list-item   v-if="item.acta !== false && comite &&
                        item.acta_aprobada === false && item.fecha_evaluacion_acta !== 0">
                      <v-btn class="mr-3" @click="evaluar(item.id, 'acta')" color="info">
                        Re-Evaluar Acta
                      </v-btn>
                    </v-list-item>
                    <v-list-item v-if="item.contrato !== false && legal">
                      <v-btn class="mr-3" target="_blank" :href="item.contrato" color="primary">
                        Descargar Contrato
                      </v-btn>
                    </v-list-item>
                    <v-list-item v-if="item.contrato !== false && legal &&
                        !item.contrato_aprobado && item.fecha_evaluacion_contrato === 0">
                      <v-btn class="mr-3" @click="evaluar(item.id, 'contrato')" color="primary">
                        Evaluar Contrato
                      </v-btn>
                    </v-list-item>
                    <v-list-item v-if="item.contrato !== false && legal &&
                        item.contrato_aprobado === false && item.fecha_evaluacion_contrato !== 0">
                      <v-btn class="mr-3" @click="evaluar(item.id, 'contrato')" color="info">
                        Re-Evaluar Contrato
                      </v-btn>
                    </v-list-item>
                    <v-list-item  v-if="!item.dictamen">
                      <ModalDictamen
                        nombre="Subir Dictamen"
                        :solicitudId="item.id"
                        :solicitudNombre="item.nombre"
                      ></ModalDictamen>
                    </v-list-item>
                    <v-list-item  v-if="item.dictamen !== false && legal">
                      <v-btn class="mr-3" target="_blank" :href="item.dictamen" color="primary">
                        Descargar Dictamen
                      </v-btn>
                    </v-list-item>
                    <v-list-item  v-if="item.contrato_aprobado && item.dictamen && legal
                          && item.documentacion_aprobada && item.estado !== 6">
                      <v-btn class="mr-3"  @click="aprobarLegajo(item.id ,6 ,item.cliente_id)"
                        color="success">
                        Aprobar Legajo
                      </v-btn>
                    </v-list-item>
                  </v-list>
              </v-menu>
              <br>
              <span v-if="legal && item.contrato_aprobado && item.fecha_evaluacion_contrato !== 0">
                <b class="green--text">Contrato aprobado</b>
              </span>
              <span v-if="comite && item.acta_aprobada && item.fecha_evaluacion_acta !== 0">
                <b class="green--text">Acta aprobado</b>
              </span>
              <span v-if="comite && !item.acta_aprobada && item.fecha_evaluacion_acta !== 0">
                <b class="red--text">Acta Rechazada</b>
              </span>
              <span v-if="legal && !item.contrato_aprobado && item.fecha_evaluacion_contrato !== 0">
                <b class="red--text">Contrato Rechazado</b>
              </span>
            </td>
          </template>
          <template v-else>
            <td
            v-if="solicitudes.length < 1"
            colspan="5" class="text-center">
              No hay Solicitudes pendientes
            </td>
          </template>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-card>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';
import ModalDictamen from '@/components/ModalDictamen.vue';
import ModalActaContrato from '@/components/ModalActaContrato.vue';
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'Revision',
  components: {
    ModalDictamen,
    ModalActaContrato,
  },
  props: {
    legal: {
      type: Boolean,
      default: false,
    },
    comite: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    solicitudes: [],
    apiurl: '',
    listaSolicitudEstados: [],
    noresult: false,
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  }),
  methods: {
    getListaSolicitudEstados() {
      axios
        .get(
          `${API_URL}api/solicitudEstado`,
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          },
        )
        .then((response) => {
          this.listaSolicitudEstados = response.data.solicitudEstado;
        });
    },
    findEstado(idEstado) {
      const estado = this.listaSolicitudEstados.find((el) => el.id === idEstado);
      return estado.nombre;
    },
    async getData() {
      const rol = localStorage.getItem('rol');
      let url;
      if (rol === '5') {
        url = `${API_URL}api/documentacionClientes/legal/lista`;
      } else {
        url = `${API_URL}api/admin/comite`;
      }
      await axios.get(url, this.header).then((response) => {
        console.log(response);
        if (response.data.status === 'success') {
          const res = response.data.lineaCreditoCliente.map((f) => ({
            cliente: `${f.cliente.nombre} ${f.cliente.apellido}`,
            cliente_id: f.cliente.id,
            rs: f.cliente.razonSocial,
            acta: f.lcc.acta ? `${API_URL}acta/${f.lcc.acta}` : false,
            contrato: f.lcc.contrato ? `${API_URL}contrato/${f.lcc.contrato}` : false,
            acta_aprobada: f.lcc.acta_aprobada || false,
            contrato_aprobado: f.lcc.contrato_aprobado || false,
            dictamen: f.lcc.dictamen ? `${API_URL}dictamen/${f.lcc.dictamen}` : false,
            fecha_evaluacion_acta: f.lcc.fecha_evaluacion_acta,
            fecha_evaluacion_contrato: f.lcc.fecha_evaluacion_contrato,
            fecha_eval_contrato_format: moment(f.lcc.fecha_evaluacion_contrato).format('DD/MM/YYYY'),
            fecha_eval_acta_format: moment(f.lcc.fecha_evaluacion_acta).format('DD/MM/YYYY'),
            tipo: 'lineaCredito',
            nombre: f.lineaCredito.nombre,
            id: f.lcc.id,
            tableid: `l${f.lcc.id}`,
            estado: f.lcc.estado_id,
            documentacion_aprobada: f.documentacion_aprobada,
            informe_elevacion: f.lcc.informe_elevacion,
          }));

          if (this.legal) {
            this.solicitudes = res.filter((el) => (el.estado === 2 && el.dictamen === false)
            || el.documentacion_aprobada === true);
          } else {
            this.solicitudes = res;
          }
        } else {
          this.noresult = true;
        }
      });
    },
    evaluar(legajoid, tipo) {
      const url = `${API_URL}api/lineaCreditoClientes/${tipo}/evaluar`;
      const data = {
        id: legajoid,
        eval: false,
      };
      this.$swal.fire({
        title: `Esta por evaluar el ${tipo}`,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Aprobar',
        denyButtonText: 'Rechazar',
      }).then((result) => {
        if (result.isConfirmed) {
          data.eval = true;
          axios.post(url, data, this.header).then((r) => {
            this.$swal.fire(r.data.message, '', 'success').then((rr) => {
              console.log(rr);
              this.$router.go(this.$router.currentRoute);
            });
          });
        } else if (result.isDenied) {
          axios.post(url, data, this.header).then((r) => {
            this.$swal.fire(r.data.message, '', 'error').then((rr) => {
              console.log(rr);
              this.$router.go(this.$router.currentRoute);
            });
          });
        }
      });
    },
    aprobarLegajo(legajoId, estadoAprobado, clienteId) {
      const url = `${API_URL}api/servicio/clientes`;
      const data = {
        lineaCreditoCliente_id: legajoId,
        estado: estadoAprobado,
        cliente_id: clienteId,
      };
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      this.$swal.fire({
        title: 'Aprobar',
        text: '¿Desea aprobar el legajo?',
        showCancelButton: true,
        confirmButtonColor: '#22bb33',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('Boton Confirmacion');
          axios.put(url, data, header)
            .then((response) => {
              console.log('response');
              this.cliente = response.data.cliente;
              this.alert = true;
            })
            .catch((error) => {
              console.log(error);
            });
          this.$router.go(this.$router.currentRoute);
        } else if (result.isDenied) {
          axios.post(url, data, this.header).then((r) => {
            this.$swal.fire(r.data.message, '', 'error').then((rr) => {
              console.log(rr);
              this.$router.go(this.$router.currentRoute);
            });
          });
        }
      });
    },
  },
  created() {
    this.getData();
    this.getListaSolicitudEstados();
  },
};
</script>
<style>
.swal2-title, .swal2-content, .swal2-styled {
  font-family: Roboto,sans-serif !important;
}
</style>
