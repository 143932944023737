<template>
  <div align-items="center" justify-content="center">
    <div class="row">
      <div class="col-lg-6">
        <h2 class="mt-2 mb-1">Créditos</h2>
      </div>
      <div class="col-lg-6">
        <div style="float: right">
          <v-col md="2">
            <v-btn
              class="boton-exportar"
              color="primary"
              :loading="loadingExcel"
              @click="exportarExcel()"
              block
              >Exportar Excel</v-btn
            >
            <br />
          </v-col>
        </div>
      </div>
    </div>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        item-key="id"
        :loading="loading"
        :no-data-text="'No hay resultados'"
        :footer-props="{
          'show-current-page': true,
        }"
      >
        <template v-slot:item.gestionar="{ item }">
          <v-btn
            :to="{
              name: 'Gestionar',
              params: {
                id: item.legajo,
                tipoCredito: item.tipo,
                cliente_id: item.cliente_id,
                estado: item.estado_id,
                fechaDesembolso: item.otorgado,
              },
            }"
            color="primary"
          >
            Gestionar
          </v-btn>
        </template>
        <template
          v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }"
        >
          <v-text-field
            v-if="header.customFilter"
            class="filter-input"
            v-model="filters[header.value]"
            append-icon="mdi-magnify"
            clearable
            hide-details
            solo
            single-line
          ></v-text-field>
          <v-text-field
            v-if="!header.customFilter"
            class="filter-input-disabled"
            hide-details
            solo
            single-line
            disabled
          ></v-text-field>
          <span class="column-title">{{ header.text }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { API_URL } from '@/common/config';
import axios from 'axios';
import moment from 'moment';
import { VDataTable } from 'vuetify/lib';
import xlsx from 'xlsx';

export default {
  props: {
    resultados: Array,
  },
  name: 'ListaCreditos',
  components: {
    VDataTable,
  },
  data: () => ({
    export: false,
    loading: false,
    loadingExcel: false,
    solicitudes: [],
    observacion: '',
    listaEstado: [],
    values: '',
    headers: [
      {
        text: 'N° Legajo',
        value: 'legajo',
        align: 'center',
        width: 120,
        sortable: true,
        customFilter: (value, item) => item.id.includes(value),
      },
      {
        text: 'Operatoria',
        value: 'operatoria',
        align: 'center',
        width: 260,
        sortable: true,
        customFilter: (value, item) =>
          item.operatoria.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'DNI/CUIT',
        value: 'dniCuit',
        align: 'center',
        width: 160,
        sortable: false,
        customFilter: (value, item) => item.dniCuit.includes(value),
      },
      {
        text: 'Cliente',
        value: 'clienteTable',
        align: 'center',
        sortable: true,
        customFilter: (value, item) =>
          item.clienteTable.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Estado',
        value: 'estado',
        align: 'center',
        sortable: true,
        customFilter: (value, item) =>
          item.estado.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Gestionar',
        value: 'gestionar',
        align: 'center',
        width: 160,
        sortable: false,
      },
    ],
    filters: {},
  }),
  computed: {
    filteredItems() {
      const filteredItems = this.resultados.filter((item) => {
        if (this.filters['id']) {
          const filterValue = parseInt(this.filters['id'], 10);
          return item['id'] === filterValue;
        }
        return Object.keys(this.filters).every((key) => {
          if (key === 'nro_legajo') return true;
          const filterValue = this.filters[key];
          if (!filterValue) return true;

          return item[key].toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      return filteredItems;
    },
  },
  methods: {
    formatDate(date) {
      const fecha = moment(date).locale('es-AR');
      if (!fecha) return 'SIN FECHA';
      return moment(fecha).format('DD/MM/YYYY');
    },
    

    async exportarExcel() {
      this.loadingExcel = true;
      let list = this.filteredItems.map((cc) => ({
        Legajo: cc.id,
        DNICUIT: cc.dniCuit,
        Cliente: cc.cliente,
        Operatoria: cc.operatoria,
        Estado: cc.estado,
      }));
      let nombre = `Listado Créditos  - ${this.values}`;
      const cs = xlsx.utils.json_to_sheet(list);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, cs, 'CC');
      xlsx.writeFile(wb, `${nombre}.xlsx`);
      this.loadingExcel = false;
    },
  },

  created() {
    moment.locale('es');
  },
};
</script>
<style>
th,
td {
  padding: 0 3px !important;
}
i {
  margin-left: 5px !important;
}
input {
  font-size: 14px;
  font-weight: 500;
  color: #1976d2 !important;
}
.v-input__control {
  margin: 5px;
}
.v-input__slot {
  height: 30px;
  min-height: 30px !important;
  margin: 6px 0 0 0;
  padding: 0 6px !important;
}
.filter-input-disabled .v-input__control .v-input__slot {
  background-color: rgb(239, 239, 239) !important;
  visibility: hidden !important;
}
.column-title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 1rem !important;
}
.btn-exportar {
  margin-left: 10%;
  margin-top: 4%;
}
.boton-exportar {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
/*.select-estado {
  flex-direction: 'row';
  align-items: 'center';
  justify-content: 'space-between';
} */
</style>
