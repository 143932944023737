var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mt-5"},[_vm._v("Creditos Regulares")]),_c('br'),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.otorgados,"item-key":"option"},scopedSlots:_vm._u([{key:"item.credito",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.colorCredito[item.credito]}},[_vm._v(" "+_vm._s(item.credito)+" ")])]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Opciones "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","to":{
                  name: 'Gestionar',
                  params: {
                    id: item.opciones,
                  }},"color":"primary"}},[_vm._v(" Gestionar ")])],1),_c('v-list-item',[_c('Auditoria',{attrs:{"lccId":item.opciones}})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }