<template>
  <div align-items="center" justify-content="center">
    <v-row class="select-estado">
      <v-col cols="2"></v-col>
      <v-col cols="2"></v-col>
      <v-col md="2">
        <v-autocomplete
          :disabled="loading"
          @change="getAprobados"
          v-model="values"
          :items="estadosAprobados"
          auto-select-first
          clearable
          deletable-chips
          small-chips
          solo
          label="Seleccione el tipo de Crédito"
          outlined
          hide-details
        ></v-autocomplete>
      </v-col>
    </v-row>

    <div class="row">
      <div class="col-lg-6">
        <h2 class="mt-2 mb-1">Créditos Aprobados</h2>
      </div>
      <div class="col-lg-6">
        <div style="float: right">
          <v-col md="2">
            <v-btn
              class="boton-exportar"
              color="primary"
              :loading="loadingExcel"
              @click="exportarExcel()"
              block
              >Exportar Excel</v-btn
            >
            <br />
          </v-col>
        </div>
      </div>
    </div>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        item-key="id"
        :loading="loading"
        :no-data-text="'No hay resultados'"
        :footer-props="{
          'show-current-page': true,
        }"
      >
        <template v-slot:item.desembolso="{ item }">
          <span v-if="item.desembolso"> {{ formatDate(item.desembolso) }}</span>
          <span v-else>S/Fecha</span>
        </template>
        <template v-slot:item.options="{ item }">
          <v-btn
            :to="{
              name: 'Gestionar',
              params: {
                id: item.id,
                tipoCredito: item.tipo,
                cliente_id: item.cliente_id,
                estado: item.estado,
              },
            }"
            color="primary"
          >
            Gestionar
          </v-btn>
        </template>
        <template
          v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }"
        >
          <v-text-field
            v-if="header.customFilter"
            class="filter-input"
            v-model="filters[header.value]"
            append-icon="mdi-magnify"
            clearable
            hide-details
            solo
            single-line
          ></v-text-field>
          <v-text-field
            v-if="!header.customFilter"
            class="filter-input-disabled"
            hide-details
            solo
            single-line
            disabled
          ></v-text-field>
          <span class="column-title">{{ header.text }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { API_URL } from '@/common/config';
import axios from 'axios';
import moment from 'moment';
import xlsx from 'xlsx';

export default {
  props: {
    estado: Number,
    isEjecutivo: Boolean,
  },
  data: () => ({
    loading: false,
    loadingExcel: false,
    values: '',
    filtrar: [],
    solicitudes: [],
    fideicomisosCliente: [],
    lineasCreditoCliente: [],
    listaSolicitudEstados: [],
    estadosAprobados: [
      { text: 'LIQUIDAR' },
      { text: 'A DESEMBOLSAR' },
      { text: 'AMORTIZAR' },
    ],
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
    noresult: false,
    headers: [
      {
        text: 'N° Legajo',
        value: 'id',
        align: 'center',
        width: 120,
        sortable: true,
        customFilter: (value, item) => item.id.includes(value),
      },
      {
        text: 'Fecha Desembolso',
        value: 'desembolso',
        align: 'center',
        width: 170,
        sortable: true,
        customFilter: (value, item) =>
          moment(item.desembolso).format('DD/MM/YYYY').includes(value),
      },
      {
        text: 'Operatoria',
        value: 'nombre',
        align: 'center',
        width: 350,
        sortable: true,
        customFilter: (value, item) =>
          item.nombre.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'DNI/CUIT',
        value: 'dniCuit',
        align: 'center',
        width: 160,
        sortable: false,
        customFilter: (value, item) => item.dniCuit.includes(value),
      },
      {
        text: 'Cliente',
        value: 'clienteTable',
        align: 'center',
        sortable: true,
        customFilter: (value, item) =>
          item.clienteTable.toLowerCase().includes(value.toLowerCase()),
      },
      {
        text: 'Opciones',
        value: 'options',
        align: 'center',
        width: 160,
        sortable: false,
      },
    ],
    filters: {},
  }),
  computed: {
    filteredItems() {
      const filt = this.filtrar;
      const filteredItems = filt.filter((item) => {
        if (this.filters['id']) {
          const filterValue = parseInt(this.filters['id'], 10);
          return item['id'] === filterValue;
        }
        return Object.keys(this.filters).every((key) => {
          if (key === 'id') return true;
          const filterValue = this.filters[key];
          if (!filterValue) return true;

          if (key === 'desembolso') {
            return moment(item.desembolso)
              .utcOffset(-3)
              .format('DD/MM/YYYY')
              .includes(filterValue);
          }
          return item[key].toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      return filteredItems;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return 'SIN FECHA';
      return moment(date).format('DD/MM/YYYY');
    },
    checkFechaDesembolso(item) {
      return item.desembolso ? 'fecha-desembolso-on' : 'fecha-desembolso-off';
    },
    getAprobados() {
      if (this.values === null) {
        return;
      }
      this.resetComponent();

      this.filtrar = this.solicitudes;
      if (this.values === 'LIQUIDAR') {
        this.filtrar = this.filtrar.filter(
          (item) => item.liquidaciones.length === 0
        );
      }
      if (this.values === 'A DESEMBOLSAR') {
        this.filtrar = this.filtrar.filter(
          (item) => item.liquidaciones.length > 0 && item.desembolso === ''
        );
      }
      if (this.values === 'AMORTIZAR') {
        this.filtrar = this.filtrar.filter(
          (item) =>
            item.desembolso !== '' &&
            item.liquidaciones.length > 0 &&
            !item.cuadro
        );
      }
    },
    resetComponent() {
      if (this.isEjecutivo) this.filtrar = [];
    },

    async getData() {
      this.loading = true;
      let url = `${API_URL}api/admin/unidadGestionClientes`;
      await axios.get(url, this.header).then((response) => {
        if (response.data.status === 'success') {
          const res = response.data.fideicomisoClientes.map((f) => ({
            dni: f.cliente.dni,
            cuit: f.cliente.cuit,
            cliente: `${f.cliente.nombre} ${f.cliente.apellido}`,
            cliente_id: f.cliente.id,
            rs: f.cliente.razonSocial,
            tipo: 'fideicomiso',
            nombre: f.fideicomiso.nombre,
            id: f.fcc[0].id,
            tableid: `f${f.fcc[0].id}`,
            estado: f.fcc[0].estado,
          }));
          this.solicitudes = res;
          this.filtrar = res;
          this.fideicomisosCliente = response.data.fideicomisoClientes;

          console.log('RESPONSE APROBADOS: ', response.data);
        } else {
          this.noresult = true;
        }
      });
      url = `${API_URL}api/admin/servicioClientes/operatoria/${this.estado}`;
      await axios.get(url, this.header).then((response) => {
        if (response.data.status === 'success') {
          const auxRes = response.data.lineaCreditoCliente;
          const res = auxRes.map((f) => ({
            dni: f.lcc.dni,
            cuit: f.lcc.cuit,
            //ADD new property
            dniCuit: f.lcc.dni ? f.lcc.dni : f.lcc.cuit,
            cliente: `${f.lcc.apellido} ${f.lcc.nombre}`,
            cliente_id: f.cliente_id,
            rs: f.lcc.razonSocial,
            //ADD new property
            clienteTable:
              f.lcc.apellido !== '' && f.lcc.nombre !== ''
                ? `${f.lcc.apellido} ${f.lcc.nombre}`
                : f.lcc.razonSocial,
            acta: f.lcc.acta ? `${API_URL}acta/${f.lcc.acta}` : false,
            contrato: f.lcc.contrato
              ? `${API_URL}contrato/${f.lcc.contrato}`
              : false,
            acta_aprobada: f.lcc.acta_aprobada || false,
            contrato_aprobado: f.lcc.contrato_aprobado || false,
            tipo: 'lineaCredito',
            nombre: f.lcc.lineaCredito_name,
            desembolso: f.lcc.fecha_desembolso
              ? Date.parse(moment(f.lcc.fecha_desembolso, 'DD/MM/YYYY'))
              : '',
            id: f.lcc.lcc_id,
            tableid: `l${f.lcc.lcc_id}`,
            estado: f.lcc.estado_id,
            liquidaciones: f.liquidaciones,
          }));
          this.solicitudes = this.solicitudes.concat(res);
          this.filtrar = this.solicitudes;
        } else {
          this.noresult = true;
        }
        this.loading = false;
      });
    },
    findEstado(idEstado) {
      const estado = this.listaSolicitudEstados.find(
        (el) => el.id === idEstado
      );
      return estado.nombre;
    },
    getListaSolicitudEstados() {
      axios
        .get(`${API_URL}api/solicitudEstado`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.listaSolicitudEstados = response.data.solicitudEstado;
        });
    },
    async exportarExcel() {
      this.loadingExcel = true;

      let list = this.filteredItems.map((cc) => ({
        Legajo: cc.id,
        FechaDesembolso: cc.desembolso ? cc.desembolso : 'S/Fecha',
        Operatoria: cc.nombre,
        DNICUIT: cc.dniCuit,
        Cliente: cc.cliente,
      }));
      let nombre = `Listado Créditos Aprobados - ${this.values}`;
      const cs = xlsx.utils.json_to_sheet(list);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, cs, 'CC');
      xlsx.writeFile(wb, `${nombre}.xlsx`);
      this.loadingExcel = false;
    },
  },
  created() {
    this.getData();
    this.getListaSolicitudEstados();
  },
};
</script>
<style>
th,
td {
  padding: 0 3px !important;
}
input {
  font-size: 14px;
  font-weight: 500;
  color: #1976d2 !important;
}
.v-input__control {
  margin: 5px;
}
.v-input__slot {
  height: 30px;
  min-height: 30px !important;
  margin: 6px 0 0 0;
}
.filter-input-disabled .v-input__control .v-input__slot {
  background-color: rgb(239, 239, 239) !important;
  visibility: hidden !important;
}
.column-title {
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 1rem;
}
.fecha-desembolso-off {
  color: black;
}
.fecha-desembolso-on {
  color: black;
}
</style>
