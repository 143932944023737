var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mt-5"},[_vm._v("Créditos Regulares")]),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"item-key":"id","loading":_vm.loading,"no-data-text":'No hay resultados',"footer-props":{
        'show-current-page': true,
      }},scopedSlots:_vm._u([{key:"item.otorgado",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.otorgado)))])]}},{key:"item.estado",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.estado.nombre))])]}},{key:"item.gestionar",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"to":{
            name: 'Gestionar',
            params: {
              id: item.legajo,
              tipoCredito: item.tipo,
              cliente_id: item.cliente_id,
              estado: item.estado_id,
            },
          },"color":"primary"}},[_vm._v(" Gestionar ")])]}},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(ref){
          var header = ref.header;
return [(header.customFilter)?_c('v-text-field',{staticClass:"filter-input",attrs:{"append-icon":"mdi-magnify","clearable":"","hide-details":"","solo":"","single-line":""},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}}):_vm._e(),(!header.customFilter)?_c('v-text-field',{staticClass:"filter-input-disabled",attrs:{"hide-details":"","solo":"","single-line":"","disabled":""}}):_vm._e(),_c('span',{staticClass:"column-title"},[_vm._v(_vm._s(header.text))])]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }