<template>
    <v-dialog v-model="visibleModalInformeComprobante" width="800px" persistent>
        <v-card>
            <v-card-title>
                <span class="text-h5">Informe Comprobante</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <template>
                            <v-menu
                                v-model="menu1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="fechaInicio"
                                    label="Desde fecha"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="fechaInicio"
                                @input="menu1 = false"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="fechaFinal"
                                        label="Hasta fecha"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="fechaFinal"
                                    @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                            :items="['Liquidado', 'No Liquidado']"
                            label="Estado"
                            v-model= estado
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="informe(0)"
                                class="mr-3 ml-5 mb-2"
                                color="info"
                            >
                                Ver informe
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn @click="informe(1)"
                                class="mr-3 mb-2"
                                color="info"
                            >
                                Guardar Informe
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn @click="informe(2)"
                                class="mr-3 mb-2"
                                color="success"
                            >
                                Guardar Excel
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                @click="salir"
                                color="red darken-1"
                                class="white--text mb-2"
                            >
                                Salir
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios';
import logo from '@/common/logo';
import { API_URL } from '@/common/config';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import xlsx from 'xlsx';

export default {
  props: ['visibleModalInformeComprobante'],
  data: () => ({
    dialog: false,
    estado: 'Liquidado',
    comprobantes: [],
    menu1: false,
    menu2: false,
    fechaInicio: '',
    fechaFinal: '',
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  methods: {
    async informe(tipo) {
      const query = {
        method: 'post',
        url: `${API_URL}api/comprobante/noliquidados`,
        headers: {
          token: localStorage.getItem('token'),
        },
        data: {
          liquidado: this.estado.toLowerCase(),
          fecha_inicio: this.fechaInicio,
          fecha_final: this.fechaFinal,
        },
      };
      const { data: { status, message, comprobantes } } = await axios(query);
      if (status === 'success') this.comprobantes = comprobantes;
      if (status === 'error') console.error(message);
      this.exportPdf(tipo);
    },
    exportPdf(tipo) {
      const doc = new JsPDF('l', 'mm', [297, 410]);
      const columnsHands = Object.keys(this.comprobantes[0]).map((el) => {
        const title = el.replace('nro', 'Nº')
          .replace('_', ' ')
          .replace(/(^\w|\s\w)/g, (l) => l.toUpperCase());
        return { title, dataKey: el };
      });
      const height = 50;
      const imgData = logo;
      doc.addImage(imgData, 'PNG', 10, 5, 48, 20);
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('Informe Comprobantes', 15, 30);
      doc.setFontSize(13);
      doc.text(`Fecha: ${moment().format('DD/MM/YYYY')}`, 150, 30);
      doc.setFontSize(15);
      doc.text(`Periodo: ${moment().format('DD/MM/YYYY')}`, 15, 40);
      doc.autoTable(columnsHands, this.comprobantes, {
        startY: height,
        columnStyles: { text: { cellWidth: 'auto' } },
      });
      if (tipo === 0) {
        window.open(doc.output('bloburl'), '_blank');
      }
      if (tipo === 1) {
        doc.save(`Informe Comprobantes ${moment().format('DD/MM/YYYY')}.pdf`);
        this.fechaInicio = '';
        this.fechaFinal = '';
        this.$emit('update:visibleModalInformeComprobante', false);
      }
      if (tipo === 2) {
        const informes = this.comprobantes.map((el) => {
          const row = columnsHands.reduce((acc, e) => {
            acc[e.title] = el[e.dataKey];
            return acc;
          }, {});
          return row;
        });
        const ws = xlsx.utils.json_to_sheet(informes);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'People');
        xlsx.writeFile(wb, 'sheetjs.xlsx');
      }
    },
    salir() {
      this.fechaInicio = '';
      this.fechaFinal = '';
      this.$emit('update:visibleModalInformeComprobante', false);
    },
  },
};
</script>
<style>
.v-date-picker-table--date td {
  padding: 0px !important;
}
</style>
