<template>
<div>
<h2
class="mt-5">Creditos Regulares</h2>
<br>
<v-card>
  <v-data-table
    dense
    :headers="headers"
    :items="otorgados"
    item-key="option"
    class="elevation-1"
  >
  <template v-slot:item.credito="{item}">
    <v-chip class='ma-2'
      :color="colorCredito[item.credito]">
        {{ item.credito }}
      </v-chip>
  </template>
  <template v-slot:item.opciones="{item}">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Opciones
              <v-icon
              dark
              right
            >
              mdi-chevron-down
            </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn text
                :to="{
                  name: 'Gestionar',
                  params: {
                    id: item.opciones,
                  }}"
                color="primary">
                    Gestionar
              </v-btn>
            </v-list-item>
            <v-list-item>
              <Auditoria :lccId="item.opciones"></Auditoria>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
  </v-data-table>
  </v-card>
</div>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';
import Auditoria from '../ModalAuditoria.vue';

export default {
  name: 'Otorgados',
  data: () => ({
    otorgados: [],
    headers: [],
    colorCredito: {
      Regular: 'success',
      Incumplido: 'error',
    },
  }),
  components: { Auditoria },
  methods: {
    async getCreditoEstado() {
      const query = {
        method: 'GET',
        url: `${API_URL}api/admin/servicioClientes/creditoEstado`,
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const { data: { status, message, lineaCreditoCliente } } = await axios(query);
      if (status === 'success') {
        this.otorgados = lineaCreditoCliente;
        this.headers = Object.keys(lineaCreditoCliente[0]).map((el) => ({
          text: el.replace(/\w/, (letter) => letter.toUpperCase()),
          value: el,
        }));
      }
      if (status === 'error') console.error(message);
    },
    gestionar() {
      console.log('Boton gestionar');
    },
  },
  created() {
    this.getCreditoEstado();
  },
};
</script>
