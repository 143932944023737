<template>
<div>
  <h1 class="mt-15">Analisis Legal</h1>
  <hr><br>
  <v-card>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class='text-left'>Cliente</th>
          <th class='text-left'>Operatoria</th>
          <th class='text-left'>Estado</th>
          <th class='text-left'>Opciones</th>
        </tr>
      </thead>
      <tbody>
          <tr v-if="solicitudes.length < 1">
              <td colspan="4">No hay documentación pendiente de analisis</td>
          </tr>
          <tr v-for="(s, index) in solicitudes" :key="index">
            <td>
              <span v-if="s.cliente_nombre !== undefined && s.cliente_nombre !== ''">
              {{ s.cliente_nombre }} {{ s.cliente_apellido }}
              </span>
              <span v-if="s.cliente_rs !== undefined || s.cliente_rs !== '' ">
              {{ s.cliente_rs }}
              </span>
            </td>
            <td>{{s.lineaCredito_nombre}}</td>
            <td>
              <v-chip class='ma-2'
              v-if='s.estado === 1 || s.estado === 3 || s.estado === 4'
              color="warning">
                {{ s.estado_nombre }}
              </v-chip>
              <v-chip class='ma-2'
              v-if='s.estado === 5'
              color="error">
                {{ s.estado_nombre }}
              </v-chip>
              <v-chip class='ma-2'
              v-if='s.estado === 2 || s.estado === 6'
              color="success">
                {{ s.estado_nombre }}
              </v-chip>
            </td>
            <td>
              <v-btn
                :to="{
                  name: 'Gestionar',
                  params: {
                    id: s.lineacreditocliente_id, // lcc = 4
                    tipoCredito: 'lineaCredito',
                    cliente_id: s.cliente_id, // 3
                    estado: s.estado // 6
                  }}"
                color="primary">
                    Gestionar
              </v-btn>
            </td>
          </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-card>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  name: 'AnalisisLegal',
  data: () => ({
    solicitudes: [],
    documentacion: [],
    loadingDocs: false,
    doc: null,
    header: {
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('token'),
      },
    },
  }),
  methods: {
    setDocumento(item, index) {
      this.solicitudes[index].documento = item;
    },
    getData() {
      const url = `${API_URL}api/documentacionClientes/legal/`;
      axios.get(url, this.header).then((response) => {
        this.solicitudes = response.data.documentos.rows.map((r) => ({
          ...r,
          documento: null,
        }));
      });
    },
    getDocumentacion(lineacreditoId) {
      this.loadingDocs = true;
      const url = `${API_URL}api/documentacionClientes/servicio/${lineacreditoId}/${this.$route.params.id}`;
      axios.get(url, this.header).then((response) => {
        const docs = response.data.listaDocumentosCliente
          .filter((el) => el.cliente !== '' && el.documento_maestro.tipo_analisis === 'Legal');
        this.documentacion = docs;
        this.loadingDocs = false;
      });
    },
  },
  created() {
    this.getData();
    // this.getDocumentacion();
  },
};
</script>
